import AllNavbar from "../components/common/AllNavBar";
import PortifolioList from "../components/portifolio/PortifolioList";
import HousePage from "./HousePage";

const Portifolio = () => {
  return (
    <>
      <AllNavbar />
      <div className="pt-20 px-[3%] md:px-[6%] mt-32 lg:mt-12 md:12">
        <div className="text-center">
          {/* <h1
          className="mx-auto heading"
          style={{
            color: "orange",
            textAlign: "center",
            fontSize: "2em",
            margin: "20px 0",
          }}
        >
          Our Properties
        </h1> */}
          <h2 className=" section-title mx-auto text-4xl font-extrabold  text-primary">
            Our Properties
          </h2>
          {/* <h1 className="heading">excellent projects both small and complex</h1> */}
        </div>
        <PortifolioList />
        {/* <HousePage/> */}
      </div>
    </>
  );
};

export default Portifolio;
