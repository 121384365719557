import React, { useEffect, useState } from 'react';
import investtor1 from '../../../assets/mechote/photo_5766941043646907156_y.jpg';
// import investtor2 from '../../../assets/mechote/photo_6003485886437769484_y.jpg';
// import investtor3 from '../../../assets/mechote/photo_6003485886437769485_y.jpg';

const Invest = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [investtor1];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.mechotrealestate.com/reserve/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          firstname: firstName, 
          lastname: lastName,
          phonenumber: phoneNumber,
        }),
      });

      if (!response.ok) {
        const data = await response.json();
        setErrorMessage(data.detail || 'An error occurred.');
        setSuccessMessage(''); // Clear success message if error occurs
      } else {
        setErrorMessage('');
        setSuccessMessage('Reservation successful!');
        setFirstName('');
        setLastName('');
        setPhoneNumber('');

        // Remove success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      }
    } catch (error) {
      console.error('Error creating reservation:', error);
      setErrorMessage('An error occurred. Please try again.');
      setSuccessMessage(''); // Clear success message if error occurs
    }
  };

  return (
    <div className="container mx-auto py-10 px-5">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div className="md:col-span-5 flex flex-col justify-center items-center">
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Make a Reservation
          </h2>
          <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4">
            <div className="flex flex-col">
              <label htmlFor="firstName" className="mb-2 font-medium">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter First Name"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="lastName" className="mb-2 font-medium">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Last Name"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="phoneNumber" className="mb-2 font-medium">
                Phone Number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Phone Number"
                required
              />
            </div>
            {errorMessage && (
              <p className="text-red-500 mt-2">{errorMessage}</p>
            )}
            {successMessage && (
              <div className="bg-green-500 text-white p-4 rounded-lg mt-4">
                {successMessage}
              </div>
            )}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Make Reservation
            </button>
          </form>
        </div>

        {/* Display images on the right side */}
        <div className="md:col-span-7 flex justify-center items-center">
          <img
            src={images[currentImageIndex]}
            alt={`Slide ${currentImageIndex + 1}`}
            className="w-full h-auto rounded-lg object-cover shadow-md hover:scale-105 transition duration-300"
          />
        </div>
      </div>
    </div>
  );
};

export default Invest;
