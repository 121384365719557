import { useEffect, useRef, useState } from "react";
import { FiDelete, FiMoon, FiSun } from "react-icons/fi";
import { BiMenu } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaEnvelope,
  FaMapMarkerAlt,
  FaWhatsapp,
  FaTelegram,
} from "react-icons/fa";
import { navLinks } from "../../data/navLinks";
import SingleLink from "./SingleLink";
import {
  uiStore,
  closeDropdown,
  closeSidebar,
  openSidebar,
  toggleDarkMode,
} from "../../features/uiSlice";

import b1 from "../../assets/mechote/3 (1).png"
import b2 from "../../assets/mechote/4 (1).png"
import b3 from "../../assets/mechote/pic 1 (1).png"
import b4 from "../../assets/mechote/3_11zon.png"

import investtor11 from "../../assets/mechote/photo_6003485886437769483_y.jpg";
import investtor21 from "../../assets/mechote/photo_6003485886437769484_y.jpg";
import investtor31 from "../../assets/mechote/photo_6003485886437769485_y.jpg";

const Navbar = () => {
  const rootDoc = document.querySelector(":root");
  const { darkMode, isSidebarOpen } = useSelector(uiStore);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Dark mode toggle
  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };

  // Store darkmode value to localStorage;
  useEffect(() => {
    if (darkMode) rootDoc.classList.add("dark");
    else rootDoc.classList.remove("dark");
    localStorage.setItem("Martvilla-theme-mode", JSON.stringify(darkMode));
  }, [darkMode]);

  const handleClose = (e) => {
    if (!e.target.classList.contains("link")) {
      dispatch(closeDropdown());
    }
  };

  const handleCloseSidebar = (e) => {
    if (e.target.classList.contains("mobile-modal")) dispatch(closeSidebar());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/search");
  };

  // const backgroundImages = [
  //   "../../../Video/3 (1).png",
  //   "../../../Video/4 (1).png",
  //   "../../../Video/pic 1 (1).png",
  // ];
  const backgroundImages = [
    // b1,
    b2,
    b3,
    investtor11,
    investtor21,
    investtor31,
   
  ];

  const backgroundIndex = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      backgroundIndex.current =
        (backgroundIndex.current + 1) % backgroundImages.length;
      document.getElementById(
        "background-container"
      ).style.backgroundImage = `url('${
        backgroundImages[backgroundIndex.current]
      }')`;
    }, 3000); // Change slide duration as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      id="background-container"
      className="relative h-screen bg-cover md:bg-contain bg-center flex flex-col md:flex-row items-center "
      style={{
        backgroundImage: `url('${backgroundImages[0]}')`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div className="absolute top-0 left-0 mt-32 p-2 mx-2 flex items-start flex-wrap ">
        {/* Logo */}
        <img
          src="../../../images/logo.png"
          alt="Mechot Real Estate Logo"
          className="w-36 h-36 md:w-28 md:h-28"
        />

        <div className="ml-2 mt-3 md:mt-0">
          {/* Company name */}
          <h1 className="text-white text-lg font-bold md:text-2xl lg:text-3xl">
            ምቾት ሪል እስቴት
          </h1>
          {/* Additional Text */}
          <h2 className="text-lg font-semibold text-white md:text-base lg:text-2xl">
            Mechot Real Estate
          </h2>
        </div>
      </div>

      {/* Existing content */}
      {/* <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary text-3xl md:text-5xl lg:text-6xl font-bold">
        Live Comfortably
      </div> */}

      {/* nav */}
      <div
        className="navbar h-[45px] fixed w-full z-20 top-0 left-0 px-[2%]  md:px-[6%] bg-white/60 border-b backdrop-blur-sm dark:border-dark dark:bg-card-dark/60"
        onMouseOver={handleClose}
      >
        {/* Social Media Links */}
        <div className="flex flex-col md:flex-row items-center justify-between mt-1   md:px-6 lg:px-0 lg:ml-auto w-full dark:text-white">
          {/* Social Media Icons */}
          <div className="flex items-center gap-x-4 mb-4 md:mb-0">
            <a
              href="https://www.facebook.com/profile.php?id=61556330946099"
              className="text-gray-700 hover:text-orange-800 dark:text-white"
            >
              <FaFacebook className="text-xl" />
            </a>
            <a
              href="https://wa.me/message/DVFS6IEIM3USD1"
              className="text-gray-700 hover:text-orange-800 dark:text-white"
            >
              <FaWhatsapp className="text-xl" />
            </a>
            <a
              href="https://www.instagram.com/mechot_realestate?igsh=MTQzbmF3aTVxcXIwZw=="
              className="text-gray-700 hover:text-orange-800 dark:text-white dark:hover:text-orange-800"
            >
              <FaInstagram className="text-xl" />
            </a>
            <a
              href="https://t.me/mechot_realestate"
              className="text-gray-700 hover:text-orange-800 dark:text-white dark:hover:text-orange-800"
            >
              {" "}
              <FaTelegram />
            </a>
          </div>

          {/* Email Address and Address */}
          {/* <div className="flex flex-col text-center md:text-left md:flex-row dark:text-white">
            <div className="flex items-center hover:text-orange-700 dark:text-white">
              <FaEnvelope className="text-gray-700 text-lg mr-2 hover:text-orange-700 dark:text-white" />{" "}
              <p className="text-lg text-black mb-2 md:mb-0 md:mr-4 md:pr-4 font-bold hover:text-orange-700 dark:text-white">
                Email: mechotrealestate@gmail.com
              </p>
            </div>
            <div className="flex items-center hover:text-orange-700">
              <FaMapMarkerAlt className="text-black text-lg mr-2 hover:text-orange-700 dark:text-white" />{" "}
              <p className="text-lg text-gray-700 font-bold hover:text-orange-700 dark:text-white">
                Piassa Country Tower 2nd floorG2-19, Addis Ababa, Ethiopia
              </p>
            </div>
          </div> */}
        </div>

        {/* Navigation Menu */}
        <div className="flex items-center justify-between px-4 mt-2">
          <Link to="/" className="flex items-center gap-x-2">
            {/* <img
              src="../../../images/logo.png"
              alt="Mechot Real Estate Logo"
              className="w-16 h-24 md:w-16 md:h-16"
            />
            <h1 className=" text-black sm:block md:text-text-gray-700 text-lg font-bold  dark:text-white">
              Mechot Real Estate
            </h1>
            <br /> */}
          </Link>

          <div className="flex-align-center gap-x-4">
            {/*-------------------------------------- Desktop Menu------------------------------------- */}
            <ul
              className={`hidden md:flex-align-center text-md font-semibold text-black dark:text-white ${
                showSearchBar && "!hidden"
              }`}
            >
              {navLinks.map((link) => (
                <SingleLink {...link} key={link.id} />
              ))}
            </ul>

            {/*---------------------------------------- Mobile Menu------------------------------------- */}

            <div
              className={`lg:hidden mobile-modal fixed w-screen h-screen top-0 left-0 bg-black/50 z-50 opacity-0 pointer-events-none transition-a ${
                isSidebarOpen && "open"
              }`}
              onClick={handleCloseSidebar}
            >
              <ul
                className={`mobile-dialog overflow-auto absolute flex flex-col space-y-4 p-3 bg-white dark:bg-card-dark h-screen max-w-[300px] w-full -translate-x-[500px] transition-a ${
                  isSidebarOpen && "open"
                }`}
              >
                <div className="border-b flex-center-between dark:border-slate-800">
                  <p className="uppercase">menu</p>
                  <div
                    className="icon-box md:hidden"
                    onClick={() => dispatch(closeSidebar())}
                  >
                    <FiDelete />
                  </div>
                </div>
                <p className="text-2xl font-semibold mt-4 mb-2 text-center">
                  Mechot Real Estate
                </p>
                {navLinks?.map(({ id, linkText, url, subLinks }) => (
                  <ul key={id}>
                    <NavLink
                      to={url}
                      end
                      className="w-fit before:!hidden"
                      onClick={() => dispatch(closeSidebar())}
                    >
                      {linkText}
                    </NavLink>
                    {subLinks?.map(({ id, linkText, url }) => (
                      <ul key={id} className="mt-2">
                        <NavLink
                          to={url}
                          end
                          className="relative ml-8 text-sm before:hidden w-fit after:absolute after:w-2 after:h-2 after:rounded-full after:border-2 after:top-1/2 after:-translate-y-1/2 after:-left-4 dark:after:opacity-50"
                          onClick={() => dispatch(closeSidebar())}
                        >
                          {linkText}
                        </NavLink>
                      </ul>
                    ))}
                  </ul>
                ))}
              </ul>
            </div>

            <div className="space-x-2 flex-align-center">
              {/*----------------------------- Dark mode toggle-------------------------------------------------- */}
              <div
                className="bg-white shadow-md icon-box dark:bg-dark-light hover:shadow-lg hover:bg-transparent"
                onClick={handleDarkMode}
              >
                {darkMode ? <FiSun /> : <FiMoon />}
              </div>

              {/*------------------------------- Mobile Menu Toogle------------------------- */}
              <div
                className="icon-box md:hidden"
                onClick={() => dispatch(openSidebar())}
              >
                <BiMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
