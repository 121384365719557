import React from "react";

function IconCard({ title, description, icon, image }) {
  return (
    <div className="p-8 bg-gradient-to-r from-blue-50 to-white shadow-lg rounded-xl transform hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out">
      {/* Centered Image */}
      <div className="flex justify-center mb-6">
        <div className="flex items-center justify-center w-24 h-24 bg-blue-100 rounded-full overflow-hidden">
          {image ? (
            <img src={image} alt={`${title} icon`} className="w-full h-full object-contain" />
          ) : (
            <i className={`fa fa-${icon} text-blue-500 text-4xl`} />
          )}
        </div>
      </div>
      {/* Title */}
      <h2 className="text-2xl font-bold text-gray-800 mb-2 text-left">{title}</h2>
      {/* Description */}
      <p className="text-base text-gray-700 leading-relaxed text-left">
        {description}
      </p>
    </div>
  );
}

export default IconCard;
