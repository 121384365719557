import React, { useState } from "react";
import axios from "axios";
import AllNavbar from "../../components/common/AllNavBar";

const PaymentPage = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    age: "",
    gender: "",
    city: "",
    subcity: "",
    worda: "",
    house_number: "",
    kebele_id: null,
    bank_name: "",
    attachment_doc: null,
    bank_receipts: null,
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    setFieldErrors({});

    // Basic form validation
    let errors = {};

    // Check if the bank_name field is empty
    if (!formData.bank_name) {
      errors.bank_name = "This field is required.";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const form = new FormData();
    for (const key in formData) {
      if (formData[key]) {
        form.append(key, formData[key]);
      }
    }

    try {
      const response = await axios.post("https://api.mechotrealestate.com/registrations/", form, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setMessage("Registration successful!");
      console.log(response.data);
    } catch (error) {
      setError("Error submitting the form. Please check your input and try again.");
      console.error(error);
    }
  };

  return (
    <>
      <AllNavbar />
      <div className="pt-10 pb-16 mt-20 lg:mt-12 md:16">
        <div className="flex justify-center">
          <div className="w-full max-w-2xl bg-white shadow-xl rounded-xl p-8">
            <div className="text-center mb-8">
              <img className="mx-auto w-32" src="../../../images/mecht.png" alt="logo" />
              <h4 className="mb-2 mt-1 text-3xl font-semibold text-black">Mechot Real Estate</h4>
              <h4 className="text-xl font-semibold text-gray-600">You Deserve Comfort!</h4>
            </div>

            <div className="registration-form">
              <h1 className="text-2xl font-semibold text-gray-700 mb-6 text-center">Registration Form</h1>
              {message && <p className="text-green-600 mb-4 text-center">{message}</p>}
              {error && <p className="text-red-600 mb-4 text-center">{error}</p>}
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex gap-4">
                  <div className="w-1/2">
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                    />
                  </div>
                  <div className="w-1/2">
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                    />
                  </div>
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                />
                <input
                  type="text"
                  name="phone_number"
                  placeholder="Phone Number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  required
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                />
                <input
                  type="number"
                  name="age"
                  placeholder="Age"
                  value={formData.age}
                  onChange={handleChange}
                  required
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                />
                <div className="flex gap-4">
                  <div className="w-1/2">
                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="w-1/2">
                    <input
                      type="text"
                      name="city"
                      placeholder="City"
                      value={formData.city}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  name="subcity"
                  placeholder="Subcity (Optional)"
                  value={formData.subcity}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                />
                <input
                  type="text"
                  name="worda"
                  placeholder="Worda (Optional)"
                  value={formData.worda}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                />
                <input
                  type="text"
                  name="house_number"
                  placeholder="House Number (Optional)"
                  value={formData.house_number}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                />
                <div className="flex gap-4">
                  <div className="w-1/2">
                    <label className="block mb-2 text-gray-600">Kebele ID:</label>
                    <input
                      type="file"
                      name="kebele_id"
                      onChange={handleChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block mb-2 text-gray-600">Attachment Document:</label>
                    <input
                      type="file"
                      name="attachment_doc"
                      onChange={handleChange}
                      required
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <label className="block mb-2 text-gray-600">Bank Receipts:</label>
                  <input
                    type="file"
                    name="bank_receipts"
                    onChange={handleChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  />
                </div>
                <div>
                  <label className="block mb-2 text-gray-600">Bank Name:</label>
                  <input
                    type="text"
                    name="bank_name"
                    placeholder="Bank Name"
                    value={formData.bank_name}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  />
                  {fieldErrors.bank_name && (
                    <p className="text-red-600 text-sm mt-1">{fieldErrors.bank_name}</p>
                  )}
                </div>
                <button
                  type="submit"
                  className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPage;
