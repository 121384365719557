import Navbar from "../components/common/Navbar";
import {
  Brands,
  Counter,
  Featured,
  Projects,
  Services,
  Testimonial,
} from "../components/common/page-componets";
import PayementList from "../components/common/page-componets/PayementList";
// import Accordion from "../components/common/page-componets/Accordion";
import {
  Feeds,
  Filters,
  Hero,
  Invest,
  Speciality,
} from "../components/home/home-1";
import VideoPlayer from "../components/home/home-1/Video";
import PortifolioList from "../components/portifolio/PortifolioList";
import MainSeriveces from "./MainService";
import Portifolio from "./Portifolio";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="pt-16 px-2 md:px-6">
        {/* Content within this div will automatically adjust its layout and spacing for responsiveness */}
        {/* <Filters /> */}
        {/* <Speciality /> */}
        <Invest />
        <VideoPlayer />
        {/* <Services /> */}
        <MainSeriveces></MainSeriveces>
        {/* <Portifolio /> */}
        {/* <PayementList /> */}
        {/* <Counter /> */}
        {/* <Projects /> */}
        {/* <Testimonial /> */}
        {/* <Brands /> */}
        {/* <Feeds /> */}
      </div>
    </>
  );
};

export default Home;
