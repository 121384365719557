export const navLinks = [
  {
    id: 1,
    linkText: "Home",
    url: "/",

  },
  {
    id: 2,
    linkText: "About Us",
    url: "/about-us",
 
  },
  {
    id: 3,
    linkText: "Services",
    url: "/services",
  
  },
  {
    id: 4,
    linkText: "Reserve",
    url: "/payment",

  },


  {
    id: 5,
    linkText: "Properties",
    url: "/properties",
 
  },
    {
    id: 6,
    linkText: "Gallery",
    url: "/gallery",
 
  },
  {
    id: 7,
    linkText: "Contact",
    url: "/contact",
  },
];
