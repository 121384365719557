import React from "react";
import IconCard from "../components/common/IconCard";
import icon1 from "../assets/mechote/image.png"
import { services } from "../data/dummyData";



function MainSeriveces() {
  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-50 p-8">
      <h1 className="text-4xl font-extrabold text-gray-800 mb-12">Our Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl">
        
        {services.map((item, index) => (
          <IconCard
            key={index}
            title={item.name}
            description={item.text }
            icon={item.icon}
            image={item.image }
          />
        ))}
      </div>
    </div>
  );
}



export default MainSeriveces;
