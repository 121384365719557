import React, { useState } from "react";

const Form = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = "First name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!formData.message.trim()) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShowSuccessMessage(false); // Hide success message if there are errors
    } else {
      setErrors({});
      setShowSuccessMessage(true); // Show success message if the form is valid
    }
  };

  return (
    <form className="pt-10 pb-8" onSubmit={handleSubmit}>
      <div className="flex-col gap-4 flex-align-center sm:flex-row">
        <div className="flex-1 w-full">
          <p>First Name</p>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="w-full input"
            placeholder="First Name.."
          />
          {errors.firstName && (
            <p className="text-red-500 text-sm">{errors.firstName}</p>
          )}
        </div>
        <div className="flex-1 w-full">
          <p>Last Name</p>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="w-full input"
            placeholder="Last Name.."
          />
          {errors.lastName && (
            <p className="text-red-500 text-sm">{errors.lastName}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <p>Email Address</p>
        <input
          type="text"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full input"
          placeholder="Email Address.."
        />
        {errors.email && (
          <p className="text-red-500 text-sm">{errors.email}</p>
        )}
      </div>
      <div className="mt-4">
        <p>Message</p>
        <textarea
          rows={4}
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="w-full input"
          placeholder="Message.."
        ></textarea>
        {errors.message && (
          <p className="text-red-500 text-sm">{errors.message}</p>
        )}
      </div>
      <div className="mt-4 flex-center-center">
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
      {showSuccessMessage && (
        <div className="mt-4 text-center text-green-500">
          Form submitted successfully!
        </div>
      )}
    </form>
  );
};

export default Form;
