import { useDispatch, useSelector } from "react-redux";
import { projects } from "../../data/dummyData";
import SinglePortifolioCard from "./SinglePortifolioCard";
import { useEffect, useState } from "react";
import { galleryList, projectList } from "../../action/projectAction";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faBath, faExpand } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faMapMarkerAlt,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";
import { propertiesList } from "../../action/propertiesAction";

const PortifolioList = ({ flex }) => {
  const dispatch = useDispatch();
  const { loading, error, properties } = useSelector(
    (state) => state.PropertiesList
  );

  console.log("properties @ on page");

  console.log(properties);
  useEffect(() => {
    dispatch(propertiesList());
  }, [dispatch]);

  const [showDetails, setShowDetails] = useState(null);

  const handleReadMoreClick = (itemId) => {
    setShowDetails((prevId) => (prevId === itemId ? null : itemId));
  };

  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomToggle = () => {
    setIsZoomed(!isZoomed);
  };
  return (
    <div
      className={`${
        flex
          ? "flex-wrap gap-4 justify-center items-center"
          : "grid grid-cols-1 gap-6 mt-8 sm:grid-cols-2 md:grid-cols-3"
      }`}
    >
      {properties?.map((items) => (
        <div key={items.id} className="relative w-full group mb-8">
          <div className="relative overflow-hidden rounded-lg shadow-md">
            <div
              className={`relative w-full group ${isZoomed ? "zoomed-in" : ""}`}
            >
              <Link
                to={`/projectdetails/${items.id}`}
                className="block relative"
              >
                <img
                  src={items.image}
                  // src="../../../Video/photo1706160510 (1).jpeg"
                  alt={items.title}
                  className="w-full h-[300px] object-cover group-hover:scale-105 transition-transform"
                />

                {/* Zoom Image Icon */}
                <div
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer"
                  onClick={handleZoomToggle}
                >
                  <FontAwesomeIcon
                    icon={faSearchPlus}
                    className="text-white text-2xl"
                  />
                </div>
              </Link>

              {/* Location Name Text */}
              <div className="absolute bottom-4 left-4 text-white hover:text-primary">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
                {items.location}
              </div>
            </div>

            <div className="absolute inset-0 px-4 py-3 bg-gradient-to-t from-black/70 text-white transition-opacity opacity-0 group-hover:opacity-100">
              {/* Additional overlay content */}
            </div>
          </div>
          <div className="p-6">
            <div className="flex justify-between">
              {/* Your other content on the left side */}
              <h5 className="mb-3 text-lg font-bold text-gray-800 dark:text-white">
                {/* Your Other Content */}
              </h5>

              {/* Phone Call link on the right side */}
              <a
                href="tel:+251960049191"
                className="flex items-center text-lg font-extrabold text-orange-500 hover:underline"
              >
                <FontAwesomeIcon icon={faPhone} className="mr-2" />
                Phone Call
              </a>
            </div>

            <h5 className="mb-3 text-lg font-bold text-gray-800 dark:text-white hover:text-primary">
              {items.title}
            </h5>
            <p className="mb-4 pb-2 text-gray-600 dark:text-gray-400">
              {items.description}
            </p>
            <div className="flex flex-wrap items-center mb-1 text-gray-600 dark:text-gray-400">
              <div className="flex items-center mr-4">
                <span className="font-bold text-lg mr-1">{items.Bedrooms}</span>
                <FontAwesomeIcon icon={faBed} className="mr-1" />
                <span className="text-gray-800 dark:text-white">Bedrooms</span>
              </div>
              <div className="flex items-center mr-4">
                <span className="font-bold text-lg mr-1">
                  {items.Bathrooms}
                </span>
                <FontAwesomeIcon icon={faBath} className="mr-1" />
                <span className="text-gray-800 dark:text-white">Bathrooms</span>
              </div>
              <div className="flex items-center mr-4">
                <span className="font-bold text-lg mr-1">
                  {items.crossArea} Sq.Mt
                </span>
                <FontAwesomeIcon icon={faExpand} className="mr-1" />
                <span className="text-gray-800 dark:text-white">Size</span>
              </div>
            </div>

            <button
              onClick={() => handleReadMoreClick(items.id)}
              className="text-lg relative inline-flex items-center justify-center p-2 mb-2 me-2 overflow-hidden font-medium text-white rounded-lg group bg-gradient-to-r from-blue-500 to-blue-700 group-hover:from-orange-400 group-hover:to-orange-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800"
            >
              {showDetails === items.id ? "Read less" : "Read more"}
            </button>

            {showDetails === items.id && (
              <div className="mt-4 text-gray-600 dark:text-gray-400">
                <table className="w-full bg-white border border-gray-300 dark:border-gray-600 divide-y divide-gray-300 dark:divide-gray-600">
                  <tbody>
                    <tr>
                      <td className="  px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        Price
                      </td>
                      <td className=" px-4 py-2">{items.Price}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        Property Type
                      </td>
                      <td className="px-4 py-2">{items.PropertyType}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        Property Status
                      </td>
                      <td className="px-4 py-2">{items.PropertyStatus}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        Rooms
                      </td>
                      <td className="px-4 py-2">{items.Rooms}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        Bedrooms
                      </td>
                      <td className="px-4 py-2">{items.Bedrooms}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        Bathrooms
                      </td>
                      <td className="px-4 py-2">{items.Bathrooms}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        Year Delivery
                      </td>
                      <td className="px-4 py-2">{items.yeardelivery} months</td>
                    </tr>
                    {/* <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        NetArea
                      </td>
                      <td className="px-4 py-2">{items.netArea} Sq.Mt</td>
                    </tr> */}
                    <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        Size
                      </td>
                      <td className="px-4 py-2">{items.crossArea} Sq.Mt</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-600">
                        Land Area
                      </td>
                      <td className="px-4 py-2">**</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold bg-gray-100 dark:bg-gray-800">
                        Label
                      </td>
                      <td className="px-4 py-2">{items.Label}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PortifolioList;
